import React, {SyntheticEvent, useContext, useState} from "react";
import './Header.css'
import {Btn} from "../common/btn";
import {SearchContext} from "../../contex/search.contex";
import {Link} from "react-router-dom";

export const Header =() => {
    const [inputVal, setInputVal] = useState('');
    const {search, setSearch} = useContext(SearchContext);

    const setSearchFromLocalState = (e: SyntheticEvent)=>{
        e.preventDefault();
        setSearch(inputVal)
    }

    return (
        <header>

           <Link to={'/'}> <h1>
                <strong>Ads </strong> Ogłoszenia
            </h1></Link>

            <Btn to="/add" text="Dodaj ogłoszenie"/>
            <form className="search" onSubmit={setSearchFromLocalState}>
                <input type="text" value={inputVal} onChange={e => setInputVal(e.target.value)}/><Btn text={"szukaj"}/>
            </form>
        </header>
    );
}