
import React, {useContext, useEffect, useRef, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer, useMapEvent, useMapEvents,} from "react-leaflet";
import "../../utils/fix-map-icos"
import 'leaflet/dist/leaflet.css'
import './Map.css';

import {LatLngTuple, LeafletMouseEvent, Map as LeafletMap} from "leaflet";
import {SearchContext} from "../../contex/search.contex";
import {SimpleAdEntity} from 'types'
import {json} from "stream/consumers";
import {SingleAd} from "./SimpleAd";
import {apiUrl} from "../../config/api";


export const Map = ()=>{
    // const HandleClickMap =()=>{
    //     useMapEvents({
    //         click(e){
    //             setLocation(location =>[e.latlng.lat, e.latlng.lng])
    //         }
    //     })
    //     return null
    // }


    // @ts-ignore
    const markerRef = useRef<Marker>(null);
    const mapRef = useRef<LeafletMap>(null)
    const [ads, setAds] = useState<SimpleAdEntity[]>([]);
    const {search} = useContext(SearchContext);

    useEffect(()=>{
        (async()=>{
         const res = await fetch(`${apiUrl}/ad/search/${search}`);
         const data = await res.json();
         setAds(data)
        })()
    },[search])


    const handelClick = () => {
        const marker = markerRef.current;
        if (marker != null) {
            console.log(marker.getIcon())
        }
    }
    if(mapRef.current != null){
        const map = mapRef.current

    }

    return(
        <div className="map">
            <MapContainer ref={mapRef}center={[52.3529698,16.9257924,]} zoom={13}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    ads.map(ad=>(
                        <Marker key={ad.id} ref={markerRef} position={[ad.lat, ad.lon]}>
                            <Popup>
                                <SingleAd id={ad.id}></SingleAd>
                            </Popup>
                        </Marker>
                        )

                    )
                }

            </MapContainer>
        </div>
    )

}


