import React, {SyntheticEvent, useState} from "react";
import "./AddForm.css";
import {Btn} from "../common/btn";
import {Simulate} from "react-dom/test-utils";
import {geocode} from "../../utils/geocoding";
import {log} from "util";
import {apiUrl} from "../../config/api";


export const AddForm =()=>{
    const [loading, setLoading] = useState(false);

    const [form, setForm] = useState({
        name:'',
        description: '',
        price: 0,
        url: '',
        address: '',
    });
    const saveAd = async (e: SyntheticEvent)=>{
        const {address} = form
        e.preventDefault();
        setLoading(true)
        try{
          const {lat, lon} = await geocode(form.address)
            const res = await fetch(`${apiUrl}/ad`,{
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...form,
                    lat,
                    lon
                }),
            });
          const data = await res.json();
            console.log(data);
        } finally {
            setLoading(false)
        }
    }
    const updateForm =(key: string, value:any)=>{
        setForm(form=>({
            ...form,
            [key]:value,
        }))
    }
    if(loading){
        return <h2>Dodawanie ogłoszenia</h2>
    }
    return (<form onSubmit={saveAd} className="add-form">
        <h1>Dodawanie ogłoszenia</h1>
            <p>
                <label>
                    Nazwa: <br/>
                    <input type ="text"
                           name="name"
                           required
                           maxLength={99}
                           value={form.name}
                           onChange={e => updateForm('name', e.target.value)}
                    />
                </label>
            </p>
            <p>
                <label>
                    Opis: <br/>
                    <textarea name="description"
                              required
                              maxLength={999}
                              value={form.description}
                              onChange={e => updateForm('description', e.target.value)}
                    />


                </label>
            </p>
            <p>
                <label>
                    Cena: <br/>
                    <input type ="number"
                           name="price"
                           required maxLength={99}
                           value={form.price}
                           onChange={e => updateForm('price', Number(e.target.value))}
                    />
                </label>
            </p>
            <p>
                <label>
                    Adres URL: <br/>
                    <input type="url"
                           name="url"
                           maxLength={99}
                           value={form.url}
                           onChange={e => updateForm('url', e.target.value)}
                    />
                </label>
            </p>
            <p>
                <label>
                    Adres fizyczny na mapie: <br/>
                    <input type ="text"
                           name="Adress"
                           maxLength={99}
                           value={form.address}
                           onChange={e => updateForm('address', e.target.value)}
                    />
                </label>
            </p>
            <Btn text={"Zapisz"}/>
    </form>
    )
}