import React from "react";
import './btn.css';
import {Link} from "react-router-dom";

interface Props{
    text: String;
    to?: string;
}
export const Btn = (props: Props) =>(
    props.to
        ? <Link className='btn' to={props.to}>{props.text}</Link> :
    <button className={'btn'}>{props.text}</button>
)

