import React, {useEffect, useState} from "react";
import { AdEntity } from "types";
import {apiUrl} from "../../config/api";

interface Props{
    id: string
}
export const  SingleAd = (props: Props) =>{
    const [ad, setAD] = useState<AdEntity | null>(null);
    useEffect(()=>{
        (async ()=>{
            const res = await fetch(`${apiUrl}/ad/${props.id}`);
            const data = await res.json();
            setAD(data);
            console.log(data);
        })()
    }, []);
    if (ad === null){
        return<p>Wczytywanie...</p>
    }
    return <>
                <h2>{ad.name}</h2>
        <a href={ad.url} target="_blank">Idz do ogłoszenia</a>
        {ad.price ? <p><strong>{ad.price}</strong> zł</p> : null}
           </>
}